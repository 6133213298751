@font-face {
    font-family: "Roboto";
    src: url("../src/fonts/Roboto-BoldItalic.ttf");

  }

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  

.body{
    background-color: #1e2326;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    overflow-y: scroll;
    height: 100vh;
}
.fondoNegro{
    background-color: #1e2326;
}
/* HEADER */
.pagina-entera nav a {
    text-decoration: none;
    color: #dadada; 
    font-weight: bold;
    font-size: 28px;
  }
.pagina-entera .headerPau{
    font-size: xx-large;
    text-decoration: none;
    color: #1CB698;
}
.pagina-entera .header-pau nav ul{
    padding: 0;
}
.pagina-entera nav ul li {
    margin: 0 10px;
    display: inline-flex;
  }

.pagina-entera header nav ul li a:hover{
    color: #1CB698;
    cursor: pointer;
}
.nav-responsive{
    color: #ffffff;
    background-color: #1CB698;
    width: 13px;
    height: 22px;
    padding: 5px 10px;
    border-radius: 22px;
    cursor: pointer;
    display: none;
    top: 0;
    right: 0;
}

.header{
    text-align: center;
}
.headerPauContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .actor {
    font-size: 12px;
    margin-top: -25px;
    color: #dadada;
  }

/* SECCION INICIO*/
.inicio{
    /* background: linear-gradient(to top, rgba(30,35,38,.8), rgba(30,35,38,1)),
   url('../src/img/pauEnteroCortado.jpg');  */

   color: #fff;
}

.inicio .contenido-banner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.inicio .contenido-banner img{
    width: 40%;
}

.timeline-teatro {
    background: url('../src/img/foto-1.jpg');
    background-size: 40%; /* Establece el tamaño del fondo a la mitad de su ancho original */
    background-position: left; /* Alinea el fondo a la izquierda */
    background-repeat: no-repeat;
}

.timeline-audiovisual {
    background: url('../src/img/foto-2.jpg');
    background-size: 40%; /* Establece el tamaño del fondo a la mitad de su ancho original */
    background-position: right; /* Alinea el fondo a la izquierda */
    background-repeat: no-repeat;
}
.timeline-publicidad {
    background: url('../src/img/foto-4.jpg');
    background-size: 40%; /* Establece el tamaño del fondo a la mitad de su ancho original */
    background-position: right; /* Alinea el fondo a la izquierda */
    background-repeat: no-repeat;
}
  
.redes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .redes > * {
    flex-basis: calc(50% - 15px);
    margin: 5px;
  }


.inicio .contenido-banner .redes ul{
    margin: 2%;
}

.contenedor-img{
    align-content: center;
}

/* TRABAJOS */
.trabajos{
    background-color: #1e2326;
    color: #ffffff;
    padding: 50px 20px;
}
.trabajos div{
    min-width: 500px;
    min-height: 260px;
}
.trabajos h2{
    font-size: 48px;
    text-align: center;
    padding: 20px 0;
}

.trabajos .grid-container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    min-width: auto;
    min-height: auto;
    gap: 40px;
}
.formacion{
    color:#A1B3B0
}
.formacion h1{
    font-size: 22px;
    text-align: center;
    padding: 20px 0;
    color: #A1B3B0
}
  
/* FOTOS */
.portfolio{
    background-color: #1e2326;
    color: #A1B3B0;
    padding: 50px 20px;
}
.portfolio .contenido-seccion{
    max-width: 1100px;
    margin: auto;
}
.portfolio .contenido-seccion .grid-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;

}
.portfolio h2{
    font-size: 48px;
    text-align: center;
    padding: 20px 0;
}
.portfolio .grid-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    filter: grayscale(60%)

}
.portfolio .grid-container img:hover{
    filter: grayscale(0%)

}

.formacion{
    background-color: #1e2326;
    padding: 0px 50px;
}
.formacion .contenido-seccion{
    max-width: 1100px;
    margin: auto;
}
.formacion h2{
    font-size: 48px;
    text-align: center;
    padding: 10px 0;

}
/* Videos */
.video-gallery-container h1{
    font-size: 48px;
    text-align: center;
    padding: 20px 0;
    color: #A1B3B0
}
.video-gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
    background-color: #1e2326;
    color: #A1B3B0
  }
  
  .video-player {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;
    max-width: 800px;
    max-height: 600px;
    margin: 0 auto;
  }
  
  .video-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .video-list div {
    max-width: 200px;
    text-align: center;
  }
  
  @media (max-width: 600px) {
    .video-player {
      width: 100%;
      height: auto;
    }
    
    .video-list div {
      max-width: 100%;
    }
  }
  


/* CONTACTO */

.contacto{
    background-color: #1e2326;
    color: #A1B3B0;
    padding: 50px 20px;
}
.contacto .contenido-seccion{
    max-width: 1100px;
    margin: auto;
}
.contacto h2{
    font-size: 48px;
    text-align: center;
    padding: 20px 0;

}
.contacto label{
    font: 138px;
    line-height: 22px;
    margin-bottom: 20px;
}
.contacto .fila{
    display: flex;
    
}
.contacto .fila .col
{
    width: 50%;
}
.contacto .fila ul{
    padding-left: 5%;
    
}
.contacto .fila .col h3{
    font-size: 28px;
    margin-bottom: 25px;
}
.contacto ul{
    padding: 3px;
}
.contacto ul a{
    text-decoration: none;
    color: #1CB698;
}
/* BOTÓN VOLVER ARRIBA */
.back-to-top-button {
    position: fixed;
    bottom: 15px;
    right: 1px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    color: #A1B3B0;
    background-color: #333;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: opacity 0.2s ease-in-out;
    z-index: 9999;
  }
  
  .back-to-top-button:hover {
    opacity: 0.8;
  }

/*  BOTTOM 
color: #ffffff;
    background-color: #1CB698;
    width: 13px;
    height: 22px;
    padding: 5px 10px;
    border-radius: 22px;
    cursor: pointer;
    display: none;
    top: 0;
    right: 0;
    */
.bottom-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background-color: #1e232600;
    z-index: 999;
  }
.bottom-buttons li{
    list-style: none;
    padding: 5px;
}
.bottom-buttons a {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #1cb69780;
  color: #A1B3B0;
  border-radius: 0px;
  text-align: center;
  text-decoration: none;
  border-color: none;
  cursor: pointer;
  
  border-radius: 100%;
}
/* SECCION RESPONSIVE */
@media screen and (max-width:760px){
    nav{
        display: none;
    }
    .nav-responsive{
        display: block;
    }

    nav.responsive{
        display: block;
        position: fixed;
        left: 34px;
        top: 0;
        width: min-content;
        text-decoration: none;
    }
    nav.responsive ul{
        display: block !important;
        text-align: left;
        margin-left: -70px;
        margin-top: 20px;
    }
    nav.responsive ul li{
        border-bottom: 1px solid #A1B3B0;
        padding: 5px 0;
    }
    .trabajos div{
        min-width: auto;
        min-height: auto;
    }
    .timeline-teatro {
        background-image: none;
    }
    .timeline-audiovisual {
        background-image: none;
    }
    .timeline-publicidad {
        background-image: none;
    }
}